<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card card-custom card-stretch gutter-b">
          <!--begin::Header-->
          <div class="card-header border-0">
            <h3 class="card-title font-weight-bolder text-dark">Verifikasi PPB KL</h3>
            <div class="card-toolbar">

            </div>
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body pt-0">
            <!--begin::Item-->
            <div>
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th style="font-size:14px;" class="text-left">Nama Kementerian/Lembaga</th>
                      <th style="font-size:14px;" class="text-left">Tanggal Mulai</th>
                      <th style="font-size:14px;" class="text-left">Tanggal Akhir</th>
                      <th style="font-size:14px;" class="text-left">Profil PPB KL</th>
                      <th style="font-size:14px;" class="text-left">Lihat PPB KL</th>
                      <th style="font-size:14px;" class="text-left">Unduh PPB KL</th>
                      <th style="font-size:14px;" class="text-left">Unduh File BA</th>
                      <th style="font-size:14px;" class="text-left">Aksi</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in dataVerifikasi" :key="item.id" v-if="item.kode_wilayah != 31">
                      <td>
                        <div v-if="item.kode_wilayah != 31">
                          {{ item.nama_wilayah }}
                        </div>
                      </td>
                      <td>
                        <div v-if="item.kode_wilayah != 31">
                          {{ format_date(item.tanggal_mulai) }}
                        </div>
                      </td>
                      <td>
                        <div v-if="item.kode_wilayah != 31">
                          {{ format_date(item.tanggal_selesai) }}
                        </div>
                      </td>
                      <td>
                        <div v-if="item.kode_wilayah != 31">
                          <b-button @click="profil(item.kode_wilayah)" size="sm" variant="success"><i
                              class="flaticon-eye"></i></b-button>
                        </div>
                      </td>
                      <td>
												<div v-if="item.kode_wilayah != 31">
													<b-button @click="urlptsp(item.kode_wilayah)"
														variant="success" size="sm" title="Lihat PM PPB"><i
															class="flaticon2-list"></i></b-button>
												</div>
											</td>
                      <td>
                        <div v-if="item.kode_wilayah != 31">
                          <b-button :href="exportexcell" @click="exportexcel(item.kode_wilayah)" variant="success"
                            size="sm"><i class="flaticon2-download"></i></b-button>
                        </div>
                      </td>
                      <td>
                        <div v-if="item.kode_wilayah != 31">
                          <button class="btn btn-success btn-sm mr-3" @click="urlba(item.kode_wilayah)"
                            style="margin-top: 3px; margin-bottom: 3px;"><i class="flaticon2-download"></i></button>
                        </div>
                      </td>
                      <td>
                        <div v-if="item.kode_wilayah != 31">
                          <b-button @click="verifikasi(item.kode_wilayah, item.nama_wilayah)" variant="primary"><i
                              class="flaticon-interface-5"></i> Verifikasi</b-button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
            <br>
            <br>
            <!--end::Item-->
          </div>
          <!--end: Card Body-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import KTCodePreview from "@/view/content/CodePreview.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import axios from 'axios';
import moment from 'moment';
import Swal from "sweetalert2";

export default {
  data() {
    return {
      urlpenilaian: localStorage.getItem('baseapipenilaian'),
      url: localStorage.getItem('baseapi'),
      token: localStorage.getItem('id_token'),
      tahun: new Date().getFullYear(),
      tanggal: '',
      exportexcell: '',
      selectedprov: "",
      provinsi: [],
      kota: [],
      user: JSON.parse(localStorage.getItem('user')),
      headers: [
        {
          text: 'Nama',
          align: 'left',
          sortable: false,
          value: 'name',
        },
        { text: 'Tanggal', value: '' },
        { text: 'Aksi', value: '' },

      ],
      pm: [],
      dataVerifikasi: [],
      status:{
        status_sistem: null
      }
    };
  },
  components: {
    KTCodePreview
  },
  mounted() {
    this.getNow()
    this.getkegiatan()
    this.$store.dispatch(SET_BREADCRUMB, [
      //   { title: "Vue Bootstrap", route: "alert" },
      { title: "Verifikasi" }
    ]);
    this.getDataVerifikasi()
  },
  methods: {
    getNow() {
      const today = new Date();
      this.tanggal = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    },
    getkegiatan() {
      axios.get(this.url + `/tanggal_kegiatans?filter=tanggal_mulai,<=,` + this.tanggal + `;tanggal_selesai,>=,` + this.tanggal, {
        headers: {
          'xth': this.token
        }
      }).then(response => {
        console.log(response)
        //this.status.status_sistem = "Tutup Permanen"
        this.status = response.data.data;
        this.notifikasi()
      }).catch(error => {
        console.log(error)
        return error
      });;
    },
    notifikasi() {
      //   e.preventDefault();
      if (this.status.status_sistem === 'Buka') {
        Swal.fire({
          title: "",
          text: "Penilaian Mandiri saat ini telah dibuka",
          icon: "info",
          confirmButtonClass: "btn btn-secondary"
        });
      } else if (this.status.status_sistem === 'Tutup Sementara') {
        Swal.fire({
          title: "",
          text: "Penilaian Mandiri saat ini telah ditutup sementara",
          icon: "info",
          confirmButtonClass: "btn btn-secondary"
        });
      } else {
        Swal.fire({
          title: "",
          text: "Penilaian Mandiri saat ini telah ditutup",
          icon: "info",
          confirmButtonClass: "btn btn-secondary"
        });
      }
    },
    getDataVerifikasi() {
      //console.log(this.user.penempatan)
      axios.get(this.url + '/penempatan?filter=id_user,=,' + this.user.id, {
        headers: {
          'xth': this.token
        }
      }).then(response => {
        this.dataVerifikasi = response.data.data;

      });
    },
    verifikasi(idkabkota, nama_wilayah){
			axios.get(this.url + `/tanggal_kegiatans?filter=tanggal_mulai,<=,` + this.tanggal + `;tanggal_selesai,>=,` + this.tanggal, {
				headers: {
					'xth': this.token
				}
			}).then(response => {
				//console.log(response)
				//this.status.status_sistem = "Tutup Permanen";
				this.status = response.data.data;
				this.getidkabkota(idkabkota, nama_wilayah)
			}).catch(error => {
				console.log(error)
				return error
			});
		},
    getidkabkota(idkabkota, nama_wilayah) {
      console.log(idkabkota)
      localStorage.setItem('idptsp', idkabkota)
      localStorage.setItem("nama_wilayah", nama_wilayah);
      axios.get(this.url + `/pm_ppb_kl_2023?filter=idkl,=,${idkabkota};tahun,=,` + this.tahun, {
        headers: {
          'xth': this.token
        }
      })
        .then(response => {
          this.pm = response.data.data;

          if (this.pm.length == 0) {
            Swal.fire({
              title: "",
              text: "KL ini belum melakukan penilaian",
              icon: "info",
              confirmButtonClass: "btn btn-secondary"
            });
          } else {

            axios.get(this.url + `/verifikasi_ppb_kl_2023?filter=idkl,=,${idkabkota};tahun,=,${this.tahun}`, {
              headers: {
                'xth': this.token
              }
            }).then(response => {
              this.file_bap = response.data.data[0].file_ba;
              if (this.file_bap != null) {
                Swal.fire({
                  title: "Info!",
                  width: 600,
                  heightAuto: true,
                  html:
                    '<div sytle="text-align: left;">Yth. Para Surveyor</div> <br> <div sytle="text-align: left;"> Penilaian ini sudah disetujui dan berita acara telah terbit, silahkan hubungi helpdesk</div>',
                  icon: "info",
                  confirmButtonClass: "btn btn-secondary"
                });
              } else {
                localStorage.setItem('idppb', JSON.stringify(this.pm.id))

                if (this.status.status_sistem === 'Buka') {
                  Swal.fire({
                    title: "",
                    text: "Penilaian Mandiri saat ini telah dibuka, Anda tidak dapat membuka Penilaian",
                    icon: "info",
                    confirmButtonClass: "btn btn-secondary"
                  });
                } else if (this.status.status_sistem === 'Tutup Sementara') {
                  this.$router.push({ name: "ppbkl-verdok" })
                } else if (this.status.status_sistem === 'Tutup Permanen') {
                  this.$router.push({ name: "ppbkl-detail" })
                } else {
                  Swal.fire({
                    title: "",
                    text: "Tidak ada kegiatan penilaian dan verifikasi. Terima kasih!",
                    icon: "info",
                    confirmButtonClass: "btn btn-secondary"
                  });
                }
              }

              // console.log(this.file_bap)
            });
            // Swal.fire({
            // title: "Info!",
            // width: 600,
            // heightAuto: true,
            // html:
            //   '<div sytle="text-align: left;">Yth. Para Surveyor</div> <br> <div sytle="text-align: left;"> Berdasarkan petunjuk dari pimpinan Kementerian Investasi/BKPM dan saran dari Ketua Tim Ahli, dimohon kepada para Surveyor untuk melakukan pengecekan lebih teliti, untuk indikator-indikator yang diblok dengan warna kuning</div> <br> <div sytle="text-align: left;">Ketua Tim Penilai<div>',
            // icon: "info",
            // confirmButtonClass: "btn btn-secondary"
            // });
            // localStorage.setItem('idppb', JSON.stringify(response.data.data.id))
            // this.$router.push({ name: "ppbkl-detail" })
          }
          console.log(response.data.data)
        }).catch(error => {
          console.log(error)
          return error
          // this.loaddata()
        });
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format('DD-MM-YYYY')
      }
    },
    urlba(kode_wilayah) {
      axios.get(this.url + `/verifikasi_ppb_kl_2023?filter=idkl,=,${kode_wilayah};tahun,=,${this.tahun}`, {
        headers: {
          'xth': this.token
        }
      }).then(response => {
        this.file_bap = response.data.data[0].file_ba;
        if (this.file_bap == null) {
          Swal.fire({
            title: "",
            text: "File BA Belum ada",
            icon: "info",
            confirmButtonClass: "btn btn-secondary"
          });
        } else {
          window.open(this.file_bap, '_blank')
        }

        console.log(this.file_bap)
      });
      ``
    },
    exportexcel(kode_wilayah) {
      window.open(this.url+`/export/ppb_kl/`+kode_wilayah+`/`+2024, '_blank')
    },
    profil(kode_wilayah) {
      let routeData = this.$router.resolve(
        {
          path: `/list-peserta/profilppbkldetail/${kode_wilayah}`,
        });
      window.open(routeData.href, '_blank');
    },
    urlptsp(kode_wilayah) {
		let routeData = this.$router.resolve(
			{
			path: `/verifikasi/detailppbkl/${kode_wilayah}`,
			// query: {'dataParameter': kode_wilayah}
			});
		window.open(routeData.href, '_blank');
		//                window.open(`/ppk/detailpm/${kode_wilayah}`, '_blank')
		``
		},
    countDownChanged1(dismissCountDown) {
      this.code1.dismissCountDown = dismissCountDown;
    },
    countDownChanged2(dismissCountDown) {
      this.code2.dismissCountDown = dismissCountDown;
    },
    showAlert1() {
      this.code1.dismissCountDown = this.code1.dismissSecs;
    },
    showAlert2() {
      this.code2.dismissCountDown = this.code2.dismissSecs;
    }
  },
};
</script>
